.body {
  text-align: center;
	background: linear-gradient(#dc2e4e, #b51118);
	width: 50%;
	border: 4px groove rgb(59, 67, 99);
	margin: 0 auto;
	color: whitesmoke;
	border-radius: 2%;
	border-bottom: 6px groove rgb(59, 67, 99);
	text-shadow: -1px 0 #555, 0 1px #555, 1px 0 #555, 0 -1px #555;
}
header{
  width:20%;
  border: 1px solid;
  margin-top: 50%;
}
.header {
	background: linear-gradient(#fa7027, #fccb3d);
	border-radius: 3%;
	border-top: 4px groove rgb(59, 67, 99);
}

.rps-h1 {
	text-align: center;
	font-family: 'Indie Flower', cursive;
}

.scoreboard {
	border: 3px double whitesmoke;
	width: 80%;
	margin: 2% auto;
	font-size: 75px;
	border-radius: 5px;
	padding: 7%;
	font-family: 'VT323', monospace;
}

.badge {
	background: linear-gradient(#fa7027, #fccb3d);
	padding: 1%;
  margin-top:2%;
  border: 5px double rgb(59, 67, 99)
}
#user-label {
  position: absolute;
  width: 45%;
	top: 55px;
	left: 1%
}

#computer-label {
	position: absolute;
	top: 55px;
  right:1%;
  width: 45%;
}

.result-msg {
	font-size: 50px;
	font-family: 'Indie Flower', cursive;
	font-weight: bold;
}

.choices > button {
	display: inline-block;
	margin: 10%;
	margin-top: 5%;
	padding: 2%;
	border: 5px solid rgb(59, 67, 99);
	border-radius: 50%;
	color: rgb(59, 67, 99);
	font-size: 25px;
	font-family:'Indie Flower', cursive;
	background: linear-gradient(#fccb3d, #fa7027);
}

.choice:hover {
	background: linear-gradient(#b51118, #fccb3d, #fa7027, #fccb3d, #b51118);
	border: 5px solid #fccb3d;
}
.action-message {
	font-family: 'VT323', monospace;
  font-size: 45px;
  margin-top: -5%;
}
