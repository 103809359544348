/* card css */

.Card{
  flex: 1
}

.Card:hover{
  cursor: pointer;
}

.Card img{
  width: 125px;
  height: 125px;
  padding: 2%;
  border: 5px double #b51118;
  border-radius: 10%;
  background: wheat;
}

/* board css */
.Board {
  height: 700px;
  width: 600px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  background: radial-gradient(rgba(181, 17, 24,.4), rgba(255, 216, 72,.5), #fa7027, #f99c1b, #fccb3d);
  border-radius: 2%;
  border: 7px groove #b51118
}